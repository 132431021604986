/** @jsx jsx */

import { jsx, Heading } from "theme-ui"
import {
  ContentBlock,
  HeaderSpacing,
  MapImageGlobal,
} from "../../components/page"
import { Trans } from "react-i18next"

export default () => {
  return (
    <ContentBlock>
      <MapImageGlobal />
      <HeaderSpacing />
      <Heading variant="headings.1" sx={{ mb: 3 }}>
        <Trans>
          Thank you for your contribution to the Progressive International!
        </Trans>
      </Heading>
    </ContentBlock>
  )
}
